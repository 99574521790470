$primary: #ee150a;
$dark: #121026;
$light: #e6e6e6;
$body-color: #3c3b39;
$font-family-base: "Poppins", sans-serif;
$europa: "europa", sans-serif;
.font-europa {
  font-family: $europa;
  font-weight: 700;
  font-style: normal;
}
