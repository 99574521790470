@font-face {
  font-family: "HelveticaNeueLight";
  src: url('../fonts/helveticaneue-light.ttf');
}

.brand-font {
  font-family: 'HelveticaNeueLight', sans-serif;
  text-align: center;
  line-height:1;
  font-size: 0.875rem;
}

body {
  line-height: 24px;
  background-color:#0a0908;
  color:#fff;
  font-family: Arial, Helvetica, sans-serif;
  background: transparent url(../images/background.png) no-repeat center top;
}

img, video {
  max-width: 100%;
}

.landing-page {
  display: flex;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size:1.15rem;
}

.logo {
  max-width: 116px;
}

.logo-text {
  margin-top:1rem;
}

.socials {
  height:50px;
  margin-top: 3rem;
  a {
    display: inline-block;
    margin:0 10px;
  }

  img {
    max-height: 100%;
    width:auto;
  }
}